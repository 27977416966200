import React, { useState, useEffect } from 'react';
import { useServiceLogger } from '../../context/ServiceLoggerContext';
import { useAuth } from '../../context/AuthContext';
import { useDevice } from '../../context/DeviceContext';
import { fetchConfig } from '../../components/api';

const SensorTest = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentSensor, setCurrentSensor] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [outputText, setOutputText] = useState('');
  const [maxHours, setMaxHours] = useState(100);

  const { logServiceActivity } = useServiceLogger();
  const { user } = useAuth();
  const { 
    isConnected, 
    deviceDetails,
    sendCommand, 
    refreshDeviceDetails
  } = useDevice();

  useEffect(() => {
    const fetchMaxHours = async () => {
      try {
        const config = await fetchConfig();
        setMaxHours(config.Sensors);
      } catch (error) {
        console.error('Failed to fetch configuration', error);
      }
    };

    fetchMaxHours();
  }, []);

  const handleResetClick = (index) => {
    setCurrentSensor(index);
    setModalVisible(true);
  };

  const confirmReset = async () => {
    console.log('Device details:', deviceDetails);
    console.log('User details:', user);

    if (!deviceDetails._id || !user._id) {
      console.log('Missing device details or user information');
      return;
    }

    setModalVisible(false);
    logServiceActivity(deviceDetails._id, 'Reset', `Counter Reset for Sensor ${currentSensor + 1}`, user._id);

    console.log(`Sending reset command for PressureSensor${currentSensor + 1}`);
    await sendCommand('reset', { target: `pressureSensor${currentSensor + 1}` });
    refreshDeviceDetails();
  };

  const closeModal = () => {
    setModalVisible(false);
    setCurrentSensor(null);
  };

  const handleRunTestClick = async () => {
    console.log('Device details:', deviceDetails);
    console.log('User details:', user);
  
    if (!deviceDetails._id || !user._id) {
      console.log('Missing device details or user information');
      return;
    }
  
    setIsTesting(true);
    setOutputText('Test is running...\n');
  
    try {
      const response = await sendCommand('test', { target: 'pressureSensor' });
      const logs = response.result;
      const formattedLogs = logs.join('\n');
      setOutputText((prevText) => prevText + formattedLogs + '\n');
      logServiceActivity(deviceDetails._id, 'Test', 'Sensor test completed', user._id);
    } catch (error) {
      setOutputText((prevText) => prevText + `Error during test: ${error.message}\n`);
    } finally {
      setIsTesting(false);
    }
  
    refreshDeviceDetails();
  };

  const sensorData = deviceDetails?.components?.pressureSensors || [];

  return (
    <div>
      <div className="parameters-and-logs">
        <div className="component-status">
          <h3>Sensors</h3>
          <img src="/images/component.png" alt="Component" className="component-image" />
          <div className="recommended-actions">
            Pressure sensors need routine checks. Calibrate them every x months and replace them after approximately 1,000 operating hours to maintain accuracy.
          </div>
          <table className="component-status-table">
            <thead>
              <tr>
                <th>Component</th>
                <th>Used</th>
                <th>Remaining</th>
                <th>Reset</th>
              </tr>
            </thead>
            <tbody>
              {sensorData.map((triggerCount, index) => (
                <tr key={index}>
                  <td>{`Pressure Sensor ${index + 1}`}</td>
                  <td className="usage-column">{isConnected ? `${triggerCount} Hours` : '-'}</td>
                  <td className="usage-percentage">{isConnected ? `${((maxHours - triggerCount) / maxHours * 100).toFixed(2)}%` : '-'}</td>
                  <td>
                    <button 
                      className={`reset-button ${!isConnected ? 'disabled' : ''}`} 
                      onClick={() => handleResetClick(index)} 
                      disabled={!isConnected}
                    >
                      Reset
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="component-test">
        <div className="component-status">
          <h3>Pressure Sensor Test</h3>
          <div className="changelog">
            This test will ensure that the Sensors are doing x y z and operating as intended.
          </div>
          <div className="output-box">
            <h4>Test Output</h4>
            <textarea 
              id="output" 
              name="output" 
              rows="5" 
              disabled 
              placeholder="Testing output will appear here..." 
              value={outputText}
            ></textarea>
          </div>
          <div className="submit-button-container">
            <button
              type="button"
              className={`submit-button ${!isConnected ? 'disabled' : ''}`}
              onClick={handleRunTestClick}
              disabled={!isConnected || isTesting}
            >
              {isTesting ? 'Test Running' : 'Run Test'}
            </button>
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className="modal-overlay">
          <div className="service-modal">
            <h4>Are you sure you want to reset this Sensor's counter?</h4>
            <div className="service-button-group">
              <button onClick={confirmReset}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SensorTest;
