import React, { useState, useEffect } from 'react';
import { serviceCounters } from '../../controller/controller';
import { useConnection } from '../../context/ConnectionContext';

function ServiceCounterReset() {
  const [counters, setCounters] = useState({});
  const { deviceDetails } = useConnection();

  useEffect(() => {
    if (deviceDetails?._id) {
      fetchCounters();
    }
  }, [deviceDetails]);

  const fetchCounters = async () => {
    try {
      const data = await serviceCounters.get(deviceDetails._id);
      setCounters(data);
    } catch (error) {
      console.error('Error fetching service counters:', error);
    }
  };

  const handleReset = async (counterType) => {
    try {
      await serviceCounters.reset(deviceDetails._id, { counterType });
      fetchCounters(); // Refresh counters after reset
    } catch (error) {
      console.error('Error resetting counter:', error);
    }
  };

  // Rest of your component remains the same...
}

export default ServiceCounterReset;
