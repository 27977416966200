import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useConnection } from '../../context/ConnectionContext';
import { logFiles } from '../../controller/controller';  // Import the logFiles controller

const Logs = () => {
  const [remarks, setRemarks] = useState('');
  const { user } = useAuth();
  const { serialNumber, deviceDetails } = useConnection();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const logData = {
      device_id: deviceDetails._id,
      serialNumber,
      file_name: 'placeholder.txt', // Placeholder file name
      upload_date: new Date(),
      details: remarks,
      first_name: user.firstName,
    };

    try {
      await logFiles.create(logData);
      alert('Log created successfully');
      setRemarks(''); // Clear the textarea
    } catch (error) {
      console.error('Error creating log:', error);
      alert(error.response?.data?.message || 'An error occurred while creating the log');
    }
  };

  return (
    <div className="logs">
      <h3>Log a Fault</h3>
      <form onSubmit={handleSubmit}>
        <label htmlFor="remarks">Remarks</label>
        <textarea 
          id="remarks" 
          name="remarks" 
          rows="5"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        ></textarea>
        <div className="submit-button-container">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Logs;
