import React, { createContext, useContext, useState } from 'react';
import { serviceLogs } from '../controller/controller';

const ServiceLoggerContext = createContext();

export const ServiceLoggerProvider = ({ children }) => {
  const [serviceLogs, setServiceLogs] = useState([]);

  const logServiceActivity = async (activity) => {
    console.log('Logging service activity:', activity);
    setServiceLogs(prevLogs => [...prevLogs, activity]);
  };

  const clearLogs = () => {
    setServiceLogs([]);
  };

  const sendConsolidatedLogs = async (serialNumber) => {
    if (!serialNumber) {
      console.error('No serial number provided for consolidating logs');
      return;
    }

    if (serviceLogs.length === 0) {
      console.log('No logs to consolidate');
      return;
    }

    try {
      await serviceLogs.consolidate(serialNumber);
      clearLogs();
      console.log('Logs consolidated and sent successfully');
    } catch (error) {
      console.error('Error sending consolidated logs:', error);
      throw error;
    }
  };

  return (
    <ServiceLoggerContext.Provider value={{
      serviceLogs,
      logServiceActivity,
      clearLogs,
      sendConsolidatedLogs
    }}>
      {children}
    </ServiceLoggerContext.Provider>
  );
};

export const useServiceLogger = () => {
  const context = useContext(ServiceLoggerContext);
  if (!context) {
    throw new Error('useServiceLogger must be used within a ServiceLoggerProvider');
  }
  return context;
};
