import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { FaUsers } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './UserSettings.css';

const UserSettings = () => {
  const { user, updateUser } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    profilePicture: '',
    currentPassword: '',  // Current password field
    newPassword: '',  // New password field
  });
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (user) {
      let profilePictureUrl = user.profilePic && user.profilePic.includes('/images/default.png') 
        ? '/images/users/default.png' 
        : `https://app.elata.au${user.profilePic}` || '/images/users/default.png';

      setFormData(prev => ({
        ...prev,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        profilePicture: profilePictureUrl,
      }));
    }
  }, [user]);


  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must include at least one numeric digit.");
    }
    if (!/[a-zA-Z]/.test(password)) {
      errors.push("Password must include at least one letter.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must include at least one uppercase letter.");
    }
    if (!/[^a-zA-Z0-9]/.test(password)) {
      errors.push("Password must include at least one special character.");
    }
  
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData(prev => ({
      ...prev,
      phone: value
    }));
  };


const handleSave = async (e) => {
    e.preventDefault();
    const updatedData = { ...formData };

    // Validate new password if it's been provided
    if (formData.newPassword) {
      if (!formData.currentPassword) {
        alert('Please enter your current password to change your password.');
        return;
      }

      const passwordErrors = validatePassword(formData.newPassword);
      if (passwordErrors.length > 0) {
        alert('New password does not meet requirements:\n' + passwordErrors.join('\n'));
        return;
      }

      updatedData.password = formData.newPassword;
    }

    delete updatedData.newPassword;
    delete updatedData.currentPassword;

    try {
      await updateUser(updatedData, selectedFile);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(`Error updating profile: ${error.message}`);
    }
  };


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="user-settings-page">
      <div className="content">
        <div className="view-user-header">
          <div className="content-title">
            <FaUsers className="content-icon" />
            <h2>User Settings</h2>
          </div>
          <button className="back-button" onClick={handleBackClick}>Back</button>
        </div>
        <div className="view-user-content">
          <div className="user-details">
            <div className="user-info">
              <div className="info-row">
                <div className="info-item">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="info-item">
                  <label>Last Name</label>
                    <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="info-item">
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'au'}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    required
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-item">
                  <label>Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                  />
                </div>
                <div className="info-item">
                  <label>New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="save-button-container">
            <button className="save-button" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
