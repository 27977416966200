import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop, FaSearch } from 'react-icons/fa';
import moment from 'moment';
import './Devices.css';
import { useAuth } from '../../context/AuthContext';
import { devices } from '../../controller/controller';

function Devices() {
  const navigate = useNavigate();
  const [devicesList, setDevices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Name');
  const { user, logout } = useAuth();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const data = await devices.getAll();
        setDevices(data);
      } catch (error) {
        if (error.response?.status === 401) {
          console.error('Unauthorized: Logging out');
          logout();
        } else {
          console.error('Failed to fetch devices:', error);
        }
      }
    };

    fetchDevices();
  }, [logout]);

  const handleRowClick = (device) => {
    navigate(`/view-device/${device._id}`);
  };

  const handleAddClick = () => {
    navigate('/add-device');
  };

  const filteredDevices = devicesList.filter(device =>
    device.device_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    device.serial_number?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedDevices = filteredDevices.sort((a, b) => {
    switch (sortOption) {
      case 'Oldest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      case 'Newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'Name':
        return a.device_name.localeCompare(b.device_name);
      case 'Serial Number':
        return (a.serial_number || '').localeCompare(b.serial_number || '');
      case 'Users':
        return b.user_ids.length - a.user_ids.length;
      case 'Last Login':
        return new Date(b.lastLogin || 0) - new Date(a.lastLogin || 0);
      default:
        return 0;
    }
  });

  const formatDateTime = (dateTime) => {
    return dateTime ? moment(dateTime).format('DD/MM/YYYY HH:mm:ss') : 'Never';
  };

  return (
    <div className="devices-page">
      <div className="content">
        <div className="content-header">
          <div className="content-title">
            <FaLaptop className="content-icon" />
            <h2>Devices</h2>
          </div>
          <button className="add-device-button" onClick={handleAddClick}>Add Device</button>
        </div>
        <div className="search-sort">
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search by name or serial number"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="sort-dropdown">
            <label htmlFor="sort-options" className="sort-label">Sort by:</label>
            <select
              id="sort-options"
              className="sort-select"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="Oldest">Oldest</option>
              <option value="Newest">Newest</option>
              <option value="Name">Name</option>
              <option value="Serial Number">Serial Number</option>
              <option value="Users">Users</option>
              <option value="Last Login">Last Login</option>
            </select>
          </div>
        </div>
        <div className="table-section">
          <table className="devices-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Serial Number</th>
                <th>Users</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {sortedDevices.map((device, index) => (
                <tr key={index} onClick={() => handleRowClick(device)}>
                  <td>{device.device_name}</td>
                  <td>{device.serial_number || 'N/A'}</td>
                  <td>{device.user_ids.length}</td>
                  <td>{formatDateTime(device.lastLogin)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Devices;
