import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import './Layout.css';

function Header() {
  const { user } = useAuth();

  return (
    <header className="header">
      <div className="welcome">
        {user ? (
          <Link to="/settings" className="profile-link">

            <strong>{user.firstName} {user.lastName}</strong>
          </Link>
        ) : (
          <span>
            Hi! <strong>Guest</strong>
          </span>
        )}
      </div>
    </header>
  );
}

export default Header;
