import React, { useState, useEffect } from "react";
import { FaLock, FaUnlock, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import moment from 'moment';
import './ConnectedDevice.css';
import { useAuth } from '../../context/AuthContext';
import { useDevice } from '../../context/DeviceContext';
import { jsonRpcRequest } from '../../components/api';
import { BASE_URL } from '../../constants/constants';  // Adjust the path as necessary
import axios from "axios";
import { connectSerial, disconnectSerial, readFromSerialPort, requestAndSendCommand, getDeviceDetails } from '../../utils/serialUtils';
import { logFiles, serviceHistory, devices, jsonrpc, accessLogs } from '../../controller/controller';

function ConnectedDevice() {
  const {
    port,
    setPort,
    isConnected,
    setIsConnected,
    serialNumber,
    setSerialNumber,
    deviceDetails,
    setDeviceDetails,
    keepReading,
    readerRef,
    writerRef
  } = useDevice();

  const [output, setOutput] = useState("");
  const [isLocked, setIsLocked] = useState(true);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedLog, setSelectedLog] = useState(null);
  const [lastAccessLog, setLastAccessLog] = useState(null);
  const [lastServiceLog, setLastServiceLog] = useState(null);
  const [logs, setLogs] = useState([]);
  const [updatedDetails, setUpdatedDetails] = useState({
    alarm_mute: false,
    alternating_cycle: '',
    comfort_setting: '',
    cooling_feature: false,
    mode: ''
  });
  const [databaseDetails, setDatabaseDetails] = useState(null);
  const [deviceConfigChanged, setDeviceConfigChanged] = useState(false);
  const [differences, setDifferences] = useState([]);
  const [manufactureDate, setManufactureDate] = useState('');
  const { user } = useAuth();
  const commandNames = ["getDeviceDetails"];

  useEffect(() => {
    if (isConnected && serialNumber) {
      fetchLogs(serialNumber);
    } else {
      setLogs([]);
    }
  }, [isConnected, serialNumber]);

  useEffect(() => {
    if (serialNumber) {
      fetchLastAccessLog(serialNumber);
      fetchLastServiceLog(serialNumber);
      fetchDatabaseDetails(serialNumber);
    }
  }, [serialNumber]);

  // Replace the existing connect/disconnect handlers
  const handleConnect = () => {
    connectSerial({
      setPort,
      setIsConnected,
      readFromSerialPort: (selectedPort) => readFromSerialPort({
        selectedPort,
        keepReading,
        readerRef,
        serialNumber,
        setSerialNumber,
        setIsConnected,
        setDeviceDetails
      })
    });
  };

  const handleDisconnect = async () => {
    // First ensure any existing writer is released
    if (writerRef.current) {
      try {
        await writerRef.current.close();
        writerRef.current.releaseLock();
        writerRef.current = null;
      } catch (e) {
        console.warn("Error releasing writer during disconnect:", e);
      }
    }

    disconnectSerial({
      port,
      isStreaming: false,
      keepReading,
      readerRef,
      writerRef,
      setPort,
      setIsConnected,
      setSerialNumber,
      setDeviceDetails,
      setDataPoints: () => {},
      setOutput: () => setOutput(""),
      setIsStreaming: () => {},
      requestAndSendCommand
    });
  };

  // Request a command from the backend and send it to the serial device
  const requestAndSendCommand = async (commandName, target = null) => {
    try {
      console.log(`Requesting command: ${commandName}`);
      if (commandName === "getDeviceDetails") {
        const response = await jsonrpc.getDeviceDetails();
        console.log("Device details received:", response);
        return response;
      } else {
        const command = {
          method: commandName,
          params: target ? { target } : undefined
        };
        const response = await jsonrpc.send(command);
        console.log("Command response:", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching or sending command:", error);
      setOutput((prevOutput) => prevOutput + "\nError: " + error.message);
    }
  };

  // Send a JSON-RPC command to the serial device
  const sendJsonRpcCommand = async (command) => {
    if (!port || !port.writable) {
      console.warn("Attempted to send command but port is not writable.");
      alert("Please connect to a serial port first.");
      return;
    }

    try {
      // If we don't have a writer yet, create one
      if (!writerRef.current) {
        console.log("Creating new writer...");
        writerRef.current = port.writable.getWriter();
      }

      console.log("Writing command to serial port:", command);
      const encodedCommand = new TextEncoder().encode(JSON.stringify(command) + "\n");
      await writerRef.current.write(encodedCommand);
      console.log("Command written successfully.");
    } catch (error) {
      console.error("Error sending JSON-RPC command:", error);
      // If there's an error, release the writer so we can try again
      if (writerRef.current) {
        try {
          await writerRef.current.close();
          writerRef.current.releaseLock();
          writerRef.current = null;
        } catch (e) {
          console.warn("Error cleaning up writer after error:", e);
        }
      }
      throw error;
    }
  };

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      // Cleanup function
      if (writerRef.current) {
        try {
          writerRef.current.close();
          writerRef.current.releaseLock();
          writerRef.current = null;
          console.log("Writer lock released during cleanup");
        } catch (e) {
          console.warn("Error releasing writer lock during cleanup:", e);
        }
      }
    };
  }, []);

  // Function to trigger getDeviceDetails command
  const handleGetDeviceDetails = async () => {
    console.log("Triggering getDeviceDetails command...");
    try {
      const details = await requestAndSendCommand("getDeviceDetails");
      if (details) {
        setDeviceDetails(details);
      }
    } catch (error) {
      console.error("Error getting device details:", error);
    }
  };

  const handleCloseModal = () => {
    setSelectedLog(null);
  };

  // Replace the fetch functions with controller calls
  const fetchLogs = async (serialNumber) => {
    try {
      const logsData = await logFiles.getBySerialNumber(serialNumber);
      console.log('Fetched logs:', logsData);
      setLogs(logsData);
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    }
  };

  const fetchLastAccessLog = async (serialNumber) => {
    try {
      const log = await accessLogs.getLastAccess(serialNumber);
      setLastAccessLog(log);
    } catch (error) {
      console.error('Failed to fetch last access log:', error);
    }
  };

  const fetchLastServiceLog = async (serialNumber) => {
    try {
      const log = await serviceHistory.getLastService(serialNumber);
      setLastServiceLog(log);
    } catch (error) {
      console.error('Failed to fetch last service log:', error);
    }
  };

  const fetchDatabaseDetails = async (serialNumber) => {
    try {
      console.log(`Fetching database details for serial number: ${serialNumber}`);
      const data = await devices.getBySerial(serialNumber);
      console.log('Database details:', data);
      
      const dbDetails = {
        alarm_mute: data.alarm_mute,
        alternating_cycle: data.alternating_cycle?.toString() || '',
        comfort_setting: data.comfort_setting?.toString() || '',
        cooling_feature: data.cooling_feature,
        mode: data.mode || ''
      };
      setDatabaseDetails(dbDetails);
      setManufactureDate(data.manufacture_date);
    } catch (error) {
      console.error('Failed to fetch database details:', error);
    }
  };

  const fetchLogDetails = async (logId) => {
    try {
      const log = await logFiles.getById(logId);
      console.log('Fetched log details:', log);
      setSelectedLog(log);
    } catch (error) {
      console.error('Error fetching log details:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDetails({ ...updatedDetails, [name]: value });
  };

  const handleLogClick = (logId) => {
    fetchLogDetails(logId);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const handleSave = async (details = updatedDetails) => {
    if (!serialNumber) {
      console.error('Serial number is missing');
      return;
    }

    const allowedFields = [
      'alarm_mute',
      'alternating_cycle',
      'comfort_setting',
      'cooling_feature',
      'mode'
    ];

    const updates = {};
    allowedFields.forEach(field => {
      if (details[field] !== undefined) {
        updates[field] = details[field] !== '' ? details[field] : null;
      }
    });

    try {
      await requestAndSendCommand({
        commandName: "setDeviceDetails",
        target: {
          serialNumber,
          ...updates
        },
        port,
        writerRef,
        setOutput
      });

      alert('Device parameters updated successfully');
      setIsLocked(true);
      setDeviceConfigChanged(false);
      setDifferences([]);
      getDeviceDetails({ port, writerRef, setOutput });
    } catch (error) {
      console.error('Error updating device parameters:', error);
      alert('An error occurred while updating the device parameters');
    }
  };

  const handleApplyChanges = async () => {
    try {
      // Update the device configuration with the details retrieved from the database
      await handleSave(databaseDetails);
      setDeviceConfigChanged(false);
      setDifferences([]);
    } catch (error) {
      console.error('Error applying changes:', error);
    }
  };

  useEffect(() => {
    if (isConnected && serialNumber && !deviceDetails) {
      console.log("No device details found in context, fetching...");
      handleGetDeviceDetails();
    }
  }, [isConnected, serialNumber, deviceDetails]);

  // Generate a list of component names from the components data
  const getComponentNames = () => {
    const names = [];
    if (deviceDetails?.components?.compressor !== undefined) names.push("compressor");
    if (deviceDetails?.components?.airFilter !== undefined) names.push("airFilter");

    if (deviceDetails?.components?.solenoids && Array.isArray(deviceDetails.components.solenoids)) {
      deviceDetails.components.solenoids.forEach((_, index) =>
        names.push(`solenoid${index + 1}`)
      );
    }

    if (deviceDetails?.components?.pressureSensors && Array.isArray(deviceDetails.components.pressureSensors)) {
      deviceDetails.components.pressureSensors.forEach((_, index) =>
        names.push(`pressureSensor${index + 1}`)
      );
    }

    return names;
  };

  return (
    <div className="connected-device-page">
      <div className="content">
        <div className="grid-container">
          <div className="status-box">
          <h2>
              Connection Status
              {isConnected ? (
                <FaCheckCircle className="connected-status-icon green-icon" />
              ) : (
                <FaTimesCircle className="connected-status-icon red-icon" />
              )}
            </h2>
            <button 
              className="connectionbutton" 
              onClick={isConnected ? handleDisconnect : handleConnect}
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </button>
          </div>
          <div className="device-name">
            <h2></h2>
            <div className="device-details">
              <div className="device-detail">
                <span>Serial Number</span>
                <span className="value">{deviceDetails?.serialNumber || "N/A"}</span>
              </div>
              <div className="device-detail">
                <span>Firmware Version</span>
                <span className="value">{deviceDetails?.version || "N/A"}</span>
              </div>
              <div className="device-detail">
                <span>Last Service Date</span>
                <span className="value">{lastServiceLog ? new Date(lastServiceLog.service_date).toLocaleDateString() : '-'}</span>
              </div>
            </div>
          </div>
          <div className="details">
            <h3>Details</h3>
            <div className="details-info">
              <div className="detail-item">
                <span className="detail-label">Last Serviced By:</span>
                <input type="text" value={lastServiceLog ? `${lastServiceLog.technician_id?.firstName} ${lastServiceLog.technician_id?.lastName}` : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed By:</span>
                <input type="text" value={lastAccessLog ? `${lastAccessLog.user_id?.firstName} ${lastAccessLog.user_id?.lastName}` : lastAccessLog?.first_name || '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed Date:</span>
                <input type="text" value={lastAccessLog ? formatDateTime(lastAccessLog.access_time) : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Manufacture Date</span>
                <input type="text" value={manufactureDate ? new Date(manufactureDate).toLocaleDateString() : '-'} className="detail-value" readOnly />
              </div>
            </div>
          </div>







          <div className="parameters-and-logs">
            <div className="parameters">
              <h3>
                Parameters {isLocked ? <FaLock className="lock-icon" onClick={toggleLock} /> : <FaUnlock className="lock-icon unlocked" onClick={toggleLock} />}
              </h3>
              <div className="parameters-info">
                <div className="parameter-item">
                  <span className="parameter-label">Alarm Mute Setting:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="alarm_mute"
                    value={updatedDetails.alarm_mute}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value={false}>Muted</option>
                        <option value={true}>Audible</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Alternating Cycle Time:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="alternating_cycle"
                    value={updatedDetails.alternating_cycle}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="5">5 Minutes</option>
                        <option value="10">10 Minutes</option>
                        <option value="15">15 Minutes</option>
                        <option value="20">20 Minutes</option>
                        <option value="25">25 Minutes</option>
                        <option value="30">30 Minutes</option>
                        <option value="60">60 Minutes</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Comfort Pressure Setting:</span>
                  <select
                    className={`parameter-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="comfort_setting"
                    value={updatedDetails.comfort_setting}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="3">Increase 3</option>
                        <option value="2">Increase 2</option>
                        <option value="1">Increase 1</option>
                        <option value="0">Default</option>
                        <option value="-1">Decrease 1</option>
                        <option value="-2">Decrease 2</option>
                        <option value="-3">Decrease 3</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
              </div>
              <h3>Features</h3>
              <div className="features-info">
                {user?.role === 'super-admin' && (
                  <div className="feature-item">
                    <span className="feature-label">Cooling Feature:</span>
                    <select
                      className={`feature-select ${isLocked ? '' : 'unlocked'}`}
                      disabled={isLocked || !isConnected}
                      name="cooling_feature"
                      value={updatedDetails.cooling_feature}
                      onChange={handleInputChange}
                    >
                      {isConnected ? (
                        <>
                          <option value="true">On</option>
                          <option value="false">Off</option>
                        </>
                      ) : (
                        <option>-</option>
                      )}
                    </select>
                  </div>
                )}
                <div className="feature-item">
                  <span className="feature-label">Mode:</span>
                  <select
                    className={`feature-select ${isLocked ? '' : 'unlocked'}`}
                    disabled={isLocked || !isConnected}
                    name="mode"
                    value={updatedDetails.mode}
                    onChange={handleInputChange}
                  >
                    {isConnected ? (
                      <>
                        <option value="1in2">1 in 2</option>
                        <option value="1in4">1 in 4</option>
                      </>
                    ) : (
                      <option>-</option>
                    )}
                  </select>
                </div>
              </div>
              {!isLocked && (
                <div className="save-button-container">
                  <button className="save-button" onClick={() => handleSave()}>Save</button>
                </div>
              )}
              {deviceConfigChanged && !isLocked && (
                <div className="apply-changes-container">
                  <div className="separator"></div>
                  <p className="notice">A different configuration has been found in the database, would you like to save and apply these changes?</p>
                  <div className="differences">
                    <h4>Differences:</h4>
                    <ul>
                      {differences.map((diff, index) => (
                        <li key={index}>
                          <strong>{diff.key}</strong>: Device Value = {diff.deviceValue.toString()}, Database Value = {diff.databaseValue.toString()}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button className="apply-changes-button" onClick={handleApplyChanges}>Apply Changes</button>
                </div>
              )}
            </div>
            {(isConnected && logs.length > 0 && (user && (user.role === 'super-admin' || user.role === 'service-technician'))) && (
              <div className="logs">
                <h3>Logs</h3>
                <table className="logs-table">
                  <thead>
                    <tr>
                      <th>Details</th>
                      <th>User</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log) => (
                      <tr key={log._id} onClick={() => handleLogClick(log._id)}>
                        <td>{log.details}</td>
                        <td>{log.first_name ? log.first_name : log.user_id ? `${log.user_id.firstName} ${log.user_id.lastName}` : '-'}</td>
                        <td>{formatDateTime(log.access_time)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>


          </div>
      </div>



      {selectedLog && (
        <>
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="modal">
            <h2>Log Details</h2>
            <p><strong>Name:</strong> {selectedLog.first_name ? selectedLog.first_name : selectedLog.user_id ? `${selectedLog.user_id.firstName} ${selectedLog.user_id.lastName}` : '-'}</p>
            <p><strong>Comment:</strong> {selectedLog.details}</p>
            <p><strong>Date:</strong> {formatDateTime(selectedLog.access_time)}</p>
            <textarea value={selectedLog.log_content} readOnly className="log-content-textarea" />
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </>
      )}
    </div>
  );
}

export default ConnectedDevice;
