import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaLock, FaUnlock, FaLaptop, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import './ViewDevice.css';
import { useAuth } from '../../context/AuthContext';
import { devices, users, logFiles, serviceHistory } from '../../controller/controller';

function ViewDevice() {
  const [isLocked, setIsLocked] = useState(true);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [potentialUsers, setPotentialUsers] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [lastAccessLog, setLastAccessLog] = useState(null);
  const [lastServiceLog, setLastServiceLog] = useState(null);
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      try {
        const data = await devices.getById(deviceId);
        setDeviceDetails(data);
        setAuthorizedUsers(data.user_ids);
        
        const logData = await logFiles.getByDeviceId(deviceId);
        setLogs(logData);

        if (data.serial_number) {
          try {
            const lastServiceData = await serviceHistory.getLastService(data.serial_number);
            setLastServiceLog(lastServiceData);
          } catch (error) {
            setLastServiceLog(null);
          }
        }
      } catch (error) {
        console.error('Error fetching device details:', error);
      }
    };

    fetchDeviceDetails();
  }, [deviceId]);

  const toggleLock = () => {
    setIsLocked(!isLocked);
  };

  const handleSave = async () => {
    try {
      await devices.update(deviceId, { ...deviceDetails, user_ids: authorizedUsers });
      alert('Device updated successfully');
      setIsLocked(true);
    } catch (error) {
      console.error('Error updating device:', error);
      alert(error.response?.data?.message || 'An error occurred while updating the device');
    }
  };

  const handleSearchChange = async (e) => {
    setNewUser(e.target.value);

    if (e.target.value.trim() === '') {
      setPotentialUsers([]);
      return;
    }

    try {
      const data = await users.search(e.target.value);
      const filteredUsers = data.filter(user => !authorizedUsers.some(au => au._id === user._id));
      setPotentialUsers(filteredUsers);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleAddUser = async (user) => {
    try {
      await devices.addUser(deviceId, user._id);
      setAuthorizedUsers([...authorizedUsers, user]);
      setNewUser('');
      setPotentialUsers([]);
    } catch (error) {
      console.error('Error adding user:', error);
      alert(error.response?.data?.message || 'An error occurred while adding the user');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const updatedAuthorizedUsers = authorizedUsers.filter(user => user._id !== userId);
      setAuthorizedUsers(updatedAuthorizedUsers);
      await devices.update(deviceId, { ...deviceDetails, user_ids: updatedAuthorizedUsers.map(u => u._id) });
    } catch (error) {
      console.error('Error removing user:', error);
      alert(error.response?.data?.message || 'An error occurred while removing the user');
    }
  };

  const handleBackClick = () => {
    navigate('/devices');
  };

  const handleDeleteDevice = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this device?');
    if (!confirmed) return;

    try {
      await devices.delete(deviceId);
      alert('Device deleted successfully');
      navigate('/devices');
    } catch (error) {
      console.error('Error deleting device:', error);
      alert(error.response?.data?.message || 'An error occurred while deleting the device');
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/view-user/${userId}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeviceDetails({ ...deviceDetails, [name]: value });
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  const handleLogClick = async (log) => {
    try {
      const data = await logFiles.getById(log._id);
      setSelectedLog(data);
    } catch (error) {
      console.error('Error fetching log content:', error);
    }
  };

  const handleCloseModal = () => {
    setSelectedLog(null);
  };

  return (
    <div className="connected-device-page">
      <div className="content">
        <div className="view-user-header">
          <div className="content-title">
            <FaLaptop className="content-icon" />
            <h2>View Device</h2>
          </div>
          <div className="button-group">
            <button className="add-user-button" onClick={handleBackClick}>Back</button>
            {user && user.role === 'super-admin' && !isLocked && (
              <button className="delete-device-button" onClick={handleDeleteDevice}>Delete</button>
            )}
          </div>
        </div>
        <div className="grid-container">
          <div className="details">
            <h3>Details</h3>
            <div className="details-info">
              <div className="detail-item">
                <span className="detail-label">Device Name</span>
                <input type="text" value={deviceDetails.device_name} className={`detail-value ${isLocked ? '' : 'unlocked'}`} readOnly={isLocked} onChange={handleChange} name="device_name" />
              </div>
              <div className="detail-item">
                <span className="detail-label">Serial Number</span>
                <input type="text" value={deviceDetails.serial_number} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Firmware Version</span>
                <input type="text" value={deviceDetails.firmware_version} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Serviced By:</span>
                <input type="text" value={lastServiceLog ? `${lastServiceLog.technician_id.firstName} ${lastServiceLog.technician_id.lastName}` : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Serviced Date:</span>
                <input type="text" value={formatDateTime(deviceDetails.last_service)} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed By:</span>
                <input type="text" value={lastAccessLog ? `${lastAccessLog.user_id.firstName} ${lastAccessLog.user_id.lastName}` : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Last Accessed Date:</span>
                <input type="text" value={lastAccessLog ? formatDateTime(lastAccessLog.access_time) : '-'} className="detail-value" readOnly />
              </div>
              <div className="detail-item">
                <span className="detail-label">Manufacture Date:</span>
                <input type="text" value={formatDateTime(deviceDetails.manufacture_date)} className="detail-value" readOnly />
              </div>
            </div>
          </div>
          {user && user.role === 'super-admin' && (
            <div className="authorized-users">
              <h3>Authorised Users</h3>
              <div className="authorized-users-info">
                {authorizedUsers.map(user => (
                  <div key={user._id} className="user-item user-row">
                    {isLocked ? (
                      <span className="user-label" onClick={() => handleUserClick(user._id)}>{user.firstName} {user.lastName}</span>
                    ) : (
                      <>
                        <span className="user-label">{user.firstName} {user.lastName}</span>
                        <FaTrash className="delete-icon" onClick={() => handleDeleteUser(user._id)} />
                      </>
                    )}
                  </div>
                ))}
                {!isLocked && user && user.role === 'super-admin' && (
                  <div className="new-user-item">
                    <input
                      type="text"
                      className="user-search-value"
                      placeholder="Search users..."
                      value={newUser}
                      onChange={handleSearchChange}
                    />
                    {potentialUsers.length > 0 && (
                      <div className="user-suggestions">
                        {potentialUsers.map(user => (
                          <div key={user._id} className="user-suggestion" onClick={() => handleAddUser(user)}>
                            {user.firstName} {user.lastName}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="View-parameters-and-logs">
            <div className="parameters">
              <h3>Parameters {user && user.role === 'super-admin' && (isLocked ? <FaLock className="lock-icon" onClick={toggleLock} /> : <FaUnlock className="lock-icon unlocked" onClick={toggleLock} />)}</h3>
              <div className="parameters-info">
                <div className="parameter-item">
                  <span className="parameter-label">Alarm Mute Setting:</span>
                  <select className={`parameter-select ${isLocked ? '' : 'unlocked'}`} disabled={isLocked} name="alarm_mute" value={deviceDetails.alarm_mute} onChange={handleChange}>
                    <option value={true}>Muted</option>
                    <option value={false}>Audible</option>
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Alternating Cycle Time:</span>
                  <select className={`parameter-select ${isLocked ? '' : 'unlocked'}`} disabled={isLocked} name="alternating_cycle" value={deviceDetails.alternating_cycle} onChange={handleChange}>
                    <option value="5 Minutes">5 Minutes</option>
                    <option value="10 Minutes">10 Minutes</option>
                    <option value="15 Minutes">15 Minutes</option>
                    <option value="20 Minutes">20 Minutes</option>
                    <option value="25 Minutes">25 Minutes</option>
                    <option value="30 Minutes">30 Minutes</option>
                    <option value="60 Minutes">60 Minutes</option>
                  </select>
                </div>
                <div className="parameter-item">
                  <span className="parameter-label">Comfort Pressure Setting:</span>
                  <select className={`parameter-select ${isLocked ? '' : 'unlocked'}`} disabled={isLocked} name="comfort_setting" value={deviceDetails.comfort_setting} onChange={handleChange}>
                    <option value="Increase 3">Increase 3</option>
                    <option value="Increase 2">Increase 2</option>
                    <option value="Increase 1">Increase 1</option>
                    <option value="Default">Default</option>
                    <option value="Decrease 1">Decrease 1</option>
                    <option value="Decrease 2">Decrease 2</option>
                    <option value="Decrease 3">Decrease 3</option>
                  </select>
                </div>
              </div>
              <h3>Features</h3>
              <div className="features-info">
                <div className="feature-item">
                  <span className="feature-label">Cooling Feature:</span>
                  <select className={`feature-select ${isLocked ? '' : 'unlocked'}`} disabled={isLocked} name="cooling_feature" value={deviceDetails.cooling_feature} onChange={handleChange}>
                    <option value="True">On</option>
                    <option value="False">Off</option>
                  </select>
                </div>
                <div className="feature-item">
                  <span className="feature-label">Mode:</span>
                  <select className={`feature-select ${isLocked ? '' : 'unlocked'}`} disabled={isLocked} name="mode" value={deviceDetails.mode} onChange={handleChange}>
                    <option value="1 in 2">1 in 2</option>
                    <option value="1 in 4">1 in 4</option>
                  </select>
                </div>
          
              </div>
              {!isLocked && user && user.role === 'super-admin' && (
                <div className="save-button-container">
                  <button className="save-button" onClick={handleSave}>Save</button>
                </div>
              )}
            </div>
            <div className="logs">
              <h3>Logs</h3>
              <table className="logs-table">
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>User</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log) => (
                    <tr key={log._id} onClick={() => handleLogClick(log)} className="log-entry">
                      <td>{log.details}</td>
                      <td>{log.first_name}</td>
                      <td>{formatDateTime(log.upload_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedLog && (
        <>
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="modal">
            <h2>Log Details</h2>
            <p><strong>Name:</strong> {selectedLog.first_name}</p>
            <p><strong>Comment:</strong> {selectedLog.details}</p>
            <p><strong>Date:</strong> {formatDateTime(selectedLog.upload_date)}</p>
            <p><strong>Log Content:</strong></p>
            <textarea value={selectedLog.log_content} readOnly className="log-content-textarea" />
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </>
      )}
    </div>
  );
}

export default ViewDevice;
