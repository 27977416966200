import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop } from 'react-icons/fa';
import './ViewDevice.css';
import { devices } from '../../controller/controller';

function AddDevice() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    device_name: '',
    serial_number: '',
    manufacture_date: '',
    alarm_mute: false,
    alternating_cycle: '',
    comfort_setting: '',
    cooling_feature: false,
    mode: ''
  });

  const handleBackClick = () => {
    navigate('/devices');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await devices.create(formData);
      alert('Device added successfully');
      navigate('/devices');
    } catch (error) {
      console.error('Error:', error);
      alert(error.response?.data?.message || 'An error occurred while adding the device');
    }
  };

  return (
    <div className="view-device-page">
      <div className="content">
        <div className="view-device-header">
          <div className="content-title">
            <FaLaptop className="content-icon" />
            <h2>Add Device</h2>
          </div>
          <button className="back-button" onClick={handleBackClick}>Back</button>
        </div>
        <form className="device-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Device Name</label>
            <input
              type="text"
              name="device_name"
              value={formData.device_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Serial Number</label>
            <input
              type="text"
              name="serial_number"
              value={formData.serial_number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Manufacture Date</label>
            <input
              type="date"
              name="manufacture_date"
              value={formData.manufacture_date}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Alarm Mute</label>
            <select
              name="alarm_mute"
              value={formData.alarm_mute}
              onChange={handleChange}
            >
              <option value={false}>Muted</option>
              <option value={true}>Audible</option>
            </select>
          </div>
          <div className="form-group">
            <label>Alternating Cycle Time</label>
            <select
              name="alternating_cycle"
              value={formData.alternating_cycle}
              onChange={handleChange}
            >
              <option value="5">5 Minutes</option>
              <option value="10">10 Minutes</option>
              <option value="15">15 Minutes</option>
              <option value="20">20 Minutes</option>
              <option value="25">25 Minutes</option>
              <option value="30">30 Minutes</option>
              <option value="60">60 Minutes</option>
            </select>
          </div>
          <div className="form-group">
            <label>Comfort Setting</label>
            <select
              name="comfort_setting"
              value={formData.comfort_setting}
              onChange={handleChange}
            >
              <option value="3">Increase 3</option>
              <option value="2">Increase 2</option>
              <option value="1">Increase 1</option>
              <option value="0">Default</option>
              <option value="-1">Decrease 1</option>
              <option value="-2">Decrease 2</option>
              <option value="-3">Decrease 3</option>
            </select>
          </div>
          <div className="form-group">
            <label>Cooling Feature</label>
            <select
              name="cooling_feature"
              value={formData.cooling_feature}
              onChange={handleChange}
            >
              <option value={true}>On</option>
              <option value={false}>Off</option>
            </select>
          </div>
          <div className="form-group">
            <label>Mode</label>
            <select
              name="mode"
              value={formData.mode}
              onChange={handleChange}
            >
              <option value="alternating">Alternating</option>
              <option value="static">Static</option>
            </select>
          </div>
          <button type="submit" className="save-button">Save</button>
        </form>
      </div>
    </div>
  );
}

export default AddDevice;
