import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './TwoFactorAuth.css';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

function VerifyTwoFA() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const formRef = useRef(null);
  const [token, setToken] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');

  const handleVerifyClick = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/verify-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${location.state.token}`
        },
        body: JSON.stringify({
          token,
          secret: location.state.user.twoFactorSecret,
          rememberMe,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        login({ user: location.state.user, token: location.state.token });
        navigate('/'); // Navigate to dashboard or home
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Verification failed:', error);
      setError('Failed to verify. Please try again.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleVerifyClick();
    }
  };

  useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (form) {
        form.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  return (
    <div className="verify-2fa-page">
      <img src="/Enable.png" alt="Logo" className="logo" />
      <h1>Verify Two-Factor Authentication</h1>
      <form ref={formRef}>
        <div className="form-group">
          <label htmlFor="token">Enter your 2FA code</label>
          <input
            type="text"
            id="token"
            placeholder="Enter your 2FA code"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
        </div>
        <div className="remember-me">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Remember me for 30 days</label>
        </div>
        <div className="form-actions">
          <button type="button" onClick={handleVerifyClick} className="verify-button">Verify</button>
        </div>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
}

export default VerifyTwoFA;