import React, { useEffect, useState } from 'react';
import { useDevice } from '../../context/DeviceContext';
import { fetchConfig } from '../../components/api';

const ComponentStatus = ({ onComponentClick }) => {
  const [maxUsage, setMaxUsage] = useState({});
  const { isConnected, deviceDetails } = useDevice();

  useEffect(() => {
    const fetchMaxUsage = async () => {
      try {
        const config = await fetchConfig();
        setMaxUsage(config);
      } catch (error) {
        console.error('Failed to fetch configuration', error);
      }
    };

    fetchMaxUsage();
  }, []);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const getTypeFromKey = (key) => {
    if (key.toLowerCase().includes('airfilter')) return 'AirFilter';
    if (key.toLowerCase().includes('compressor')) return 'Compressor';
    if (key.toLowerCase().includes('pressuresensor')) return 'Sensors';
    if (key.toLowerCase().includes('solenoid')) return 'Solenoids';
    return 'Unknown';
  };

  const generateComponents = (isConnected, componentsData) => {
    if (typeof componentsData !== 'object' || componentsData === null) {
      console.error('componentsData is not an object:', componentsData);
      return [];
    }

    const result = [];

    Object.keys(componentsData).forEach((key) => {
      const value = componentsData[key];
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const type = getTypeFromKey(key);
          const usagePercentage = Math.floor((item / (maxUsage[type] || 100)) * 100);
          const remainingUses = (maxUsage[type] || 100) - item;

          const name = `${key.replace(/([A-Z])/g, ' $1').trim()} ${index + 1}`;
          const formattedName = capitalizeWords(name);

          if (isConnected) {
            result.push({
              name: formattedName,
              type,
              usage: `${usagePercentage}%`,
              details: `Estimated ${remainingUses} uses remaining`,
            });
          } else {
            result.push({
              name: formattedName,
              type,
              usage: '-',
              details: '-',
            });
          }
        });
      } else {
        const type = getTypeFromKey(key);
        const usagePercentage = Math.floor((value / (maxUsage[type] || 100)) * 100);
        const remainingUses = (maxUsage[type] || 100) - value;

        const name = key.replace(/([A-Z])/g, ' $1').trim();
        const formattedName = capitalizeWords(name);

        if (isConnected) {
          result.push({
            name: formattedName,
            type,
            usage: `${usagePercentage}%`,
            details: `Estimated ${remainingUses} uses remaining`,
          });
        } else {
          result.push({
            name: formattedName,
            type,
            usage: '-',
            details: '-',
          });
        }
      }
    });

    return result;
  };

  const componentStatus = generateComponents(isConnected, deviceDetails?.components);

  return (
    <div className="parameters-and-logs">
      <div className="component-status">
        <h3>Component Status</h3>
        <table className="component-status-table">
          <thead>
            <tr>
              <th>Component</th>
              <th>Usage</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {componentStatus.map((component, index) => (
              <tr key={index} onClick={() => onComponentClick(component.name)}>
                <td className="component-column">{component.name}</td>
                <td className="usage-column">
                  <div className="usage-bar-container">
                    <div className="usage-bar-background">
                      <div className="usage-bar" style={{ width: component.usage === '-' ? '0%' : component.usage }}></div>
                    </div>
                    <span className="usage-percentage">{component.usage}</span>
                  </div>
                </td>
                <td className="details-column">{component.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComponentStatus;
