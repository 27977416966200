import React, { useState, useEffect, useRef } from 'react';
import { useServiceLogger } from '../../context/ServiceLoggerContext';
import { useAuth } from '../../context/AuthContext';
import { useDevice } from '../../context/DeviceContext';
import { fetchConfig } from '../../components/api';

const FilterTest = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [outputText, setOutputText] = useState('');
  const [maxHours, setMaxHours] = useState(100);
  const initialFetchDone = useRef(false);

  const { logServiceActivity } = useServiceLogger();
  const { user } = useAuth();
  const { 
    isConnected, 
    deviceDetails,
    sendCommand, 
    refreshDeviceDetails
  } = useDevice();

  useEffect(() => {
    const fetchMaxHours = async () => {
      try {
        const config = await fetchConfig();
        setMaxHours(config.AirFilter);
      } catch (error) {
        console.error('Failed to fetch configuration', error);
      }
    };

    fetchMaxHours();
  }, []);

  const handleResetClick = () => {
    setModalVisible(true);
  };

  const confirmReset = async () => {
    console.log('Device details:', deviceDetails);
    console.log('User details:', user);

    if (!deviceDetails._id || !user._id) {
      console.log('Missing device details or user information');
      return;
    }

    setModalVisible(false);
    logServiceActivity(deviceDetails._id, 'Reset', 'Counter Reset for Air Filter', user._id);

    console.log('Sending reset command for Air Filter');
    await sendCommand('reset', { target: 'airFilter' });
    refreshDeviceDetails();
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleRunTestClick = async () => {
    console.log('Device details:', deviceDetails);
    console.log('User details:', user);

    if (!deviceDetails._id || !user._id) {
      console.log('Missing device details or user information');
      return;
    }

    setIsTesting(true);
    setOutputText('Test is running...\n');

    try {
      // Simulate a delay for the test to run
      const randomOutput = 'Test completed successfully.\nOutput: All parameters are within normal range.';
      setOutputText((prevText) => prevText + randomOutput);
      logServiceActivity(deviceDetails._id, 'Test', 'Air Filter test completed', user._id);
    } catch (error) {
      setOutputText((prevText) => prevText + `Error during test: ${error.message}\n`);
    } finally {
      setIsTesting(false);
    }
  };

  const usedHours = deviceDetails?.components?.airFilter || 0;

  return (
    <div>
      <div className="parameters-and-logs">
        <div className="component-status">
          <h3>Air Filter</h3>
          <img src="/images/component.png" alt="Component" className="component-image" />
          <div className="recommended-actions">
            Filters require regular maintenance. Inspect them every month and perform a full service every {maxHours} operating hours to ensure efficient operation.
          </div>
          <div className="usage-status">
            <div className="usage">
              <span className="label">Used</span>
              <span className="service-value">{isConnected ? `${usedHours} Hours` : '-'}</span>
            </div>
            <div className="remaining">
              <span className="label">Remaining</span>
              <span className="service-value">{isConnected ? `${((maxHours - usedHours) / maxHours * 100).toFixed(0)}%` : '-'}</span>
            </div>
          </div>
          <button
            className={`single-reset-button ${!isConnected ? 'disabled' : ''}`}
            onClick={handleResetClick}
            disabled={!isConnected}
          >
            Reset
          </button>
        </div>
      </div>
      

      {modalVisible && (
        <div className="modal-overlay">
          <div className="service-modal">
            <h4>Are you sure you want to reset this Filter's counter?</h4>
            <div className="service-button-group">
              <button onClick={confirmReset}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterTest;
