import React from 'react';
import { AuthProvider } from './context/AuthContext';
import { ConnectionProvider } from './context/ConnectionContext';
import { ServiceLoggerProvider } from './context/ServiceLoggerContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadLogFile from './pages/UploadLogFile';
import LoginPage from './pages/LoginPage';
import Users from './pages/Users/Users';
import Devices from './pages/Devices/Devices';
import AddDevice from './pages/Devices/AddDevice';
import ConnectedDevice from './pages/ConnectedDevice/ConnectedDevice';
import Service from './pages/Service/Service';
import ViewUser from './pages/Users/ViewUser';
import AddUser from './pages/Users/AddUser';
import ViewDevice from './pages/Devices/ViewDevice';
import PrivateRoute from './components/PrivateRoute';
import UserSettings from './pages/Settings/UserSettings';
import RedirectToRoleBasedHome from './components/RedirectToRoleBasedHome';
import Layout from './components/Layout';
import VerifyTwoFA from './pages/VerifyTwoFA';
import SetupTwoFA from './components/SetupTwoFA';
import SerialDeviceCommunicator from './pages/Dev/SerialCommunicator'; // Import the new component
import './App.css';
import { DeviceProvider } from './context/DeviceContext';

function App() {
  return (
    <DeviceProvider>
      <ServiceLoggerProvider>
        <AuthProvider>
          <ConnectionProvider>
            <div className="App">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/redirect" element={<RedirectToRoleBasedHome />} />
              <Route path="/upload-log" element={<UploadLogFile />} />
              <Route path="/verify-2fa" element={<VerifyTwoFA />} />
              <Route path="/setup-2fa" element={<SetupTwoFA />} />
              <Route path="/" element={<Layout />}>
                <Route path="users" element={<PrivateRoute roles={['super-admin']}><Users /></PrivateRoute>} />
                <Route path="view-user/:userId" element={<PrivateRoute roles={['super-admin']}><ViewUser /></PrivateRoute>} />
                <Route path="add-device" element={<PrivateRoute roles={['super-admin']}><AddDevice /></PrivateRoute>} />
                <Route path="add-user" element={<PrivateRoute roles={['super-admin']}><AddUser /></PrivateRoute>} />
                <Route path="devices" element={<PrivateRoute roles={['super-admin', 'service-technician']}><Devices /></PrivateRoute>} />
                <Route path="view-device/:deviceId" element={<PrivateRoute roles={['super-admin', 'service-technician']}><ViewDevice /></PrivateRoute>} />
                <Route path="connected-device" element={<PrivateRoute roles={['super-admin', 'service-technician', 'clinician']}><ConnectedDevice /></PrivateRoute>} />
                <Route path="service" element={<PrivateRoute roles={['super-admin', 'service-technician']}><Service /></PrivateRoute>} />
                <Route path="settings" element={<PrivateRoute roles={['super-admin', 'service-technician', 'clinician', 'developer']}><UserSettings /></PrivateRoute>} />
                <Route path="serial-communicator" element={<PrivateRoute roles={['super-admin', 'developer']}><SerialDeviceCommunicator /></PrivateRoute>} /> {/* Add the new route */}
              </Route>
            </Routes>
            </div>
          </ConnectionProvider>
        </AuthProvider>
      </ServiceLoggerProvider>
    </DeviceProvider>
  );
}

export default App;
