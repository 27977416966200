import axios from 'axios';


//const API_BASE_URL = 'http://localhost:5000/api';
const API_BASE_URL = 'https://app.elata.au/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Auth endpoints
export const auth = {
  login: async (credentials) => {
    const response = await api.post('/auth/login', credentials);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    return response.data;
  },

  logout: async () => {
    localStorage.removeItem('token');
    return (await api.post('/auth/logout')).data;
  },

  register: async (userData) => {
    return (await api.post('/auth/register', userData)).data;
  },

  verifyToken: async () => {
    return (await api.get('/auth/verify')).data;
  },

  refreshToken: async () => {
    const response = await api.post('/auth/refresh-token');
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    return response.data;
  },

  verify2FA: async (token, data) => {
    try {
      const response = await api.post('/auth/verify-2fa', data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('2FA Verification Error:', error.response?.data || error);
      throw error;
    }
  },

  setup2FA: async (token, data) => {
    return (await api.post('/auth/setup-2fa', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })).data;
  },

  enable2FA: async (token, data) => {
    return (await api.post('/auth/enable-2fa', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })).data;
  },

  generate2FA: async () => {
    const token = localStorage.getItem('token');
    return (await api.post('/auth/generate-2fa', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })).data;
  },
};

// Config endpoints
export const config = {
  getMaxUsage: async () => {
    return (await api.get('/config/max-usage')).data;
  },
};

// Device endpoints
export const devices = {
  getAll: async () => {
    return (await api.get('/devices')).data;
  },
  
  getById: async (id) => {
    return (await api.get(`/devices/${id}`)).data;
  },
  
  getBySerial: async (serialNumber) => {
    return (await api.get(`/devices/serial/${serialNumber}`)).data;
  },
  
  create: async (deviceData) => {
    return (await api.post('/devices', deviceData)).data;
  },
  
  update: async (id, deviceData) => {
    return (await api.put(`/devices/${id}`, deviceData)).data;
  },
  
  delete: async (id) => {
    return (await api.delete(`/devices/${id}`)).data;
  },
  
  addUser: async (deviceId, userId) => {
    return (await api.put(`/devices/${deviceId}/addUser`, { userId })).data;
  },
  
  updateSettings: async (updateData) => {
    return (await api.post('/devices/update-settings', updateData)).data;
  },
  
  updateWaiver: async (waiverData) => {
    return (await api.post('/devices/update-waiver', waiverData)).data;
  },
};

// JSON-RPC endpoints
export const jsonrpc = {
  getDeviceDetails: async () => {
    return (await api.get('/jsonrpc/getDeviceDetails')).data;
  },
  
  send: async (command) => {
    return (await api.post('/jsonrpc/command', command)).data;
  },

  streamData: async ({ action, interval }) => {
    return (await api.get(`/jsonrpc/streamData/${action}?interval=${interval}`)).data;
  },
  
  resetDevice: async (target) => {
    return (await api.get(`/jsonrpc/resetDevice/${target}`)).data;
  },
  
  testDevice: async (target) => {
    return (await api.get(`/jsonrpc/testDevice/${target}`)).data;
  },

  getPorts: async () => {
    return (await api.get('/jsonrpc/ports')).data;
  },

  readDeviceDetails: async () => {
    return (await api.get('/jsonrpc/readDeviceDetails')).data;
  },

  firmwareUpdate: async () => {
    return (await api.get('/jsonrpc/firmwareUpdate')).data;
  },

  Logging: async (enabled, level) => {
    return (await api.get(`/jsonrpc/Logging/${enabled}?level=${level || 'app'}`)).data;
  },
};

// Log Files endpoints
export const logFiles = {
  upload: async (formData) => {
    return (await api.post('/logs/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })).data;
  },
  
  create: async (logData) => {
    return (await api.post('/logs/create', logData)).data;
  },
  
  getByDeviceId: async (deviceId) => {
    return (await api.get(`/logFiles/device/id/${deviceId}`)).data;
  },
  
  getBySerialNumber: async (serialNumber) => {
    return (await api.get(`/logs/device/serial/${serialNumber}`)).data;
  },
  
  getById: async (logId) => {
    return (await api.get(`/logs/${logId}`)).data;
  },
};

// Service History endpoints
export const serviceHistory = {
  create: async (serviceData) => {
    return (await api.post('/serviceHistories/service-history', serviceData)).data;
  },
  
  getLastService: async (serialNumber) => {
    return (await api.get(`/serviceHistories/last-service/${serialNumber}`)).data;
  },
};

// Upload endpoints
export const upload = {
  profilePicture: async (formData) => {
    return (await api.post('/upload/profile-picture', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })).data;
  },
};

// Users endpoints
export const users = {
  getAll: async () => {
    return (await api.get('/users')).data;
  },
  
  getById: async (userId) => {
    return (await api.get(`/users/${userId}`)).data;
  },
  
  create: async (userData) => {
    return (await api.post('/users', userData)).data;
  },
  
  update: async (id, userData) => {
    return (await api.put(`/users/${id}`, userData)).data;
  },
  
  delete: async (id) => {
    return (await api.delete(`/users/${id}`)).data;
  },
  
  search: async (query) => {
    return (await api.get(`/users/search?q=${query}`)).data;
  },
  
  addDevice: async (userId, deviceId) => {
    return (await api.put(`/users/${userId}/addDevice`, { deviceId })).data;
  },
};

// Service-related endpoints
export const service = {
  getDeviceDetails: async () => {
    return (await api.get('/jsonrpc/getDeviceDetails')).data;
  },
  
  streamData: async (action) => {
    return (await api.get(`/jsonrpc/streamData/${action}`)).data;
  },
  
  resetDevice: async (target) => {
    return (await api.get(`/jsonrpc/resetDevice/${target}`)).data;
  },
  
  testDevice: async (target) => {
    return (await api.get(`/jsonrpc/testDevice/${target}`)).data;
  },

  getPorts: async () => {
    return (await api.get('/jsonrpc/ports')).data;
  },

  // Add methods for each component test
  testCompressor: async (data) => {
    return (await api.post('/jsonrpc/test/compressor', data)).data;
  },

  testAirFilter: async (data) => {
    return (await api.post('/jsonrpc/test/airfilter', data)).data;
  },

  testSolenoid: async (data) => {
    return (await api.post('/jsonrpc/test/solenoid', data)).data;
  },

  testPressureSensor: async (data) => {
    return (await api.post('/jsonrpc/test/pressuresensor', data)).data;
  },

  updateFirmware: async (formData) => {
    return (await api.post('/jsonrpc/firmware/update', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })).data;
  },

  readDeviceDetails: async () => {
    return (await api.get('/jsonrpc/readDeviceDetails')).data;
  },
};

export const componentStatus = {
  get: async (deviceId) => {
    return (await api.get(`/componentStatus/${deviceId}`)).data;
  },
  
  update: async (deviceId, statusData) => {
    return (await api.put(`/componentStatus/${deviceId}`, statusData)).data;
  },
};

export const serviceCounters = {
  get: async (deviceId) => {
    return (await api.get(`/serviceCounters/${deviceId}`)).data;
  },
  
  reset: async (deviceId, counterData) => {
    return (await api.post(`/serviceCounters/${deviceId}/reset`, counterData)).data;
  },
};

export const deviceConnection = {
  sendCommand: async (commandName, target = null, options = null) => {
    if (commandName === 'Logging') {
      const command = await api.get(`/jsonrpc/logging/${target}?level=${options?.level || 'app'}`);
      return command.data;
    }
    
    let command = {
      jsonrpc: "2.0",
      method: commandName,
      id: Math.floor(Math.random() * 10000)
    };
    
    return command;
  },
};

export const serviceLogs = {
  create: async (logData) => {
    return (await api.post('/serviceHistories/service-history', logData)).data;
  },
  
  getBySerial: async (serialNumber) => {
    return (await api.get(`/serviceHistories/last-service/${serialNumber}`)).data;
  },
  
  consolidate: async (serialNumber) => {
    return (await api.post('/serviceHistories/consolidate', { serialNumber })).data;
  }
};

// Add this to your controller.js if not already present
export const logs = {
  getBySerialNumber: async (serialNumber) => {
    return (await api.get(`/logs/device/serial/${serialNumber}`)).data;
  },
  
  getById: async (logId) => {
    return (await api.get(`/logs/${logId}`)).data;
  }
};

export const accessLogs = {
  getLastAccess: async (serialNumber) => {
    return (await api.get(`/accessLogs/last/${serialNumber}`)).data;
  }
};

// Export the api instance in case it's needed for custom requests
export { api };
