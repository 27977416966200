import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUsers, FaSearch } from 'react-icons/fa';
import moment from 'moment';
import './Users.css';
import { useAuth } from '../../context/AuthContext';
import { users } from '../../controller/controller';  // Import the users controller

function Users() {
  const navigate = useNavigate();
  const [usersList, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Name');
  const { user, logout } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await users.getAll();
        setUsers(data);
      } catch (error) {
        if (error.response?.status === 401) {
          console.error('Unauthorized: Logging out');
          logout();
        } else {
          console.error('Failed to fetch users:', error);
        }
      }
    };

    fetchUsers();
  }, [logout]);

  const handleRowClick = (user) => {
    navigate(`/view-user/${user._id}`);
  };

  const handleAddClick = () => {
    navigate('/add-user');
  };

  const filteredUsers = usersList.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    switch (sortOption) {
      case 'Oldest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      case 'Newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'Name':
        return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
      case 'Role':
        return a.role.localeCompare(b.role);
      case 'Devices':
        return b.devices.length - a.devices.length;
      case 'Last Login':
        return new Date(b.lastLogin) - new Date(a.lastLogin);
      default:
        return 0;
    }
  });

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
  };

  return (
    <div className="users-page">
      <div className="content">
        <div className="content-header">
          <div className="content-title">
            <FaUsers className="content-icon" />
            <h2>Users</h2>
          </div>
          <button className="add-user-button" onClick={handleAddClick}>Add User</button>
        </div>
        <div className="search-sort">
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="sort-dropdown">
            <label htmlFor="sort-options" className="sort-label">Sort by:</label>
            <select
              id="sort-options"
              className="sort-select"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="Oldest">Oldest</option>
              <option value="Newest">Newest</option>
              <option value="Name">Name</option>
              <option value="Role">Role</option>
              <option value="Devices">Devices</option>
              <option value="Last Login">Last Login</option>
            </select>
          </div>
        </div>
        <div className="table-section">
          <table className="users-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Devices</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user, index) => (
                <tr key={index} onClick={() => handleRowClick(user)}>
                  <td>{`${user.firstName} ${user.lastName}`}</td>
                  <td>{user.role}</td>
                  <td>{user.devices.length}</td>
                  <td>{user.devices[0]?.lastLogin ? formatDateTime(user.devices[0].lastLogin) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users;
