import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useServiceLogger } from './ServiceLoggerContext';
import { BASE_URL } from '../constants/constants';  // Adjust the path as necessary

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { sendConsolidatedLogs } = useServiceLogger();

  const [refreshInterval, setRefreshInterval] = useState(null);

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const login = (userData) => {
    console.log('Logging in:', userData);
    const decodedToken = jwtDecode(userData.token);
    
    if (decodedToken.exp * 1000 < Date.now()) {
      logout();
      throw new Error('Token expired');
    }

    localStorage.setItem('user', JSON.stringify(userData.user));
    localStorage.setItem('token', userData.token);
    console.log('Decoded token:', decodedToken);
    setUser({ ...userData.user, id: decodedToken.userId, role: decodedToken.role });
    
    setupTokenRefresh(decodedToken.exp);
  };

  const setupTokenRefresh = (tokenExp) => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }

    const timeUntilRefresh = (tokenExp * 1000) - Date.now() - (5 * 60 * 1000);
    if (timeUntilRefresh <= 0) return;

    const interval = setInterval(async () => {
      try {
        await refreshToken();
      } catch (error) {
        console.error('Token refresh failed:', error);
        logout();
      }
    }, timeUntilRefresh);

    setRefreshInterval(interval);
  };

  const refreshToken = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/auth/refresh`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Token refresh failed');

      const data = await response.json();
      localStorage.setItem('token', data.token);
      
      const decodedToken = jwtDecode(data.token);
      setUser(prevUser => ({ ...prevUser, id: decodedToken.userId, role: decodedToken.role }));
      
      setupTokenRefresh(decodedToken.exp);
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  const logout = async () => {
    console.log('Logging out...');
    if (refreshInterval) {
      clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
    
    const serialNumber = localStorage.getItem('serialNumber');
    if (serialNumber) {
      console.log('Sending consolidated logs before logging out...');
      await sendConsolidatedLogs(serialNumber);
    }
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('serialNumber');
    setUser(null);
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
    
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp * 1000 > Date.now();
    } catch {
      return false;
    }
  };

  const updateUser = async (updatedData, file) => {
    const formData = new FormData();
    Object.keys(updatedData).forEach(key => {
      formData.append(key, updatedData[key]);
    });
    if (file) {
      formData.append('profilePicture', file);
    }

    try {
      const token = localStorage.getItem('token');
      console.log('Token from localStorage:', token);
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${BASE_URL}/api/auth/update`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error data:', errorData);
        throw new Error('Failed to update profile');
      }

      const data = await response.json();
      setUser(data.user);
      localStorage.setItem('user', JSON.stringify(data.user));
    } catch (error) {
      console.error('Error updating profile:', error.message);
      throw error;
    }
  };

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    
    if (savedUser && token) {
      try {
        const parsedUser = JSON.parse(savedUser);
        const decodedToken = jwtDecode(token);
        
        if (decodedToken.exp * 1000 < Date.now()) {
          logout();
          return;
        }

        console.log('Decoded token on load:', decodedToken);
        setUser({ ...parsedUser, id: decodedToken.userId, role: decodedToken.role });
        setupTokenRefresh(decodedToken.exp);
      } catch (error) {
        console.error('Error processing saved authentication:', error);
        logout();
      }
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

